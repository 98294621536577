import { useState, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
// import { useToast } from "@chakra-ui/react"
// import Login from '../pages/login/Login'
import { useHistory  } from 'react-router-dom';

export const useForgotPassword = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  // const toast = useToast()
  const history = useHistory();
 
  const forgotPassword = async (email) => {
    setError(null)
    setIsPending(true)
  
    try {
      // login using email / password
      const res = await projectAuth.sendPasswordResetEmail(email).then(() => {
        // history.push('/login')
        document.getElementById("dvMesssage").className = "SuccessMessage";
        document.getElementById("dvMesssage").innerHTML = 'Success! Your password reset email has been sent.'
        
      })

      if (!isCancelled) {
        setIsPending(false)
        setError(null)
      }

    } 
    catch(err) {
      if (!isCancelled) {
        setError(err.message)
        setIsPending(false)
        // document.getElementById("dvMesssage").className = "ErrorMessage";
        // document.getElementById("dvMesssage").innerHTML=error.message;
      }
    }

  }

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { forgotPassword, isPending, error }
}