// styles
import './Jobs.css'

export default function Jobs() {
  return (
<div>

{/* <!-- ======= Hero Section ======= --> */} 
 <section className="hero-section" id="hero">

  <div className="wave">

    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>

  </div>

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">Ready to be part of our professional team?  </h1>
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Apply below to join the growing Tethered GPS team </p> 
          </div>
          
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

<main id="main">

  {/* <!-- ======= Jobs Page Section ======= --> */} 
  <section className="section" id="get-started">
    <div className="container">
      <div className="row justify-content-center text-center mb-5 jobs-box">

        <div className="col-md-5 col-sm-12" style={{ textAlign: "left" }}>    
          <p className="contact-list"><span className="list-icon bi bi-chat-left-dots"><strong className='contact-subtitle'>Email us with your application:</strong></span><br />
          <span className="center-text"><a href="mailto:jobs@decogps.com">jobs@decogps.com</a></span>
          
          </p>
        </div>

      </div>
    </div>
  </section>

</main>{/* <!-- End #main --> */}

</div>
  )
}
 