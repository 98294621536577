import './Footer.css'
import SocialLinks from './social-links'
//images
// import DashboardIcon from '../assets/dashboard_icon.svg'
// import AddIcon from '../assets/add_icon.svg'
// import Logo from '../assets/img/tethered_banner.png'

// images import 
// import TestimonialPhoto1 from '../assets/img/softland-template/person_1.jpg'
// import TestimonialPhoto2 from '../assets/img/softland-template/person_2.jpg'
// import TestimonialPhoto3 from '../assets/img/softland-template/person_3.jpg'
import GooglePlayBadge from '../assets/img/icons/google-play-badge.png'
import AppleAppBadge from '../assets/img/icons/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import LinkTreeQRCode from '../assets/img/QR-code/tethered-QR-code-link-tree-Jimena-May2023.png'
import AppleQRCode from '../assets/img/QR-code/qr-code-apple.png'

// SWIPER FILES IMPORT
// // <!-- Import these CSS files first because there is no css files for swiper latest version-->

// import "swiper/swiper-bundle.min.css";
// import "swiper/swiper.min.css";

// // <!--Then you can import these form here-->

// import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";

import React from "react";
import SwiperCore, { Autoplay, Navigation, EffectCoverflow, Pagination } from "swiper";
// import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import "./App.css";

SwiperCore.use([Autoplay, Navigation, EffectCoverflow, Pagination]);

export default function Footer() {
    
    return (
      <div className="footer-content">
         <div>

 {/* <!-- ======= Testimonials Section ======= --> */}

 {/* <section className="section border-top border-bottom">
  <div className="container testimonial-section">
    <div className="row justify-content-center text-center mb-5">
      
        <h2 className="section-heading">What People Are Saying:</h2>
      
    </div>

    <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: false,
        }}
        pagination={true}
        autoplay={{ 
          delay: 3000,
        }}
        className="mySwiper"
      >
        <SwiperSlide>
        <div className="testimonial-swiper-slide">
              <div className="review text-center">
                <p className="stars">
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill muted"></span>
                </p>
                <h3>(Testimonials Coming Soon)</h3>
                <blockquote>
                  <p>...</p>
                </blockquote>

                <p className="review-user">
                  <img src={TestimonialPhoto1} alt="user-review" className="img-fluid rounded-circle mb-3" />
                  <span className="d-block">
                    <span className="text-black">Jean Doe</span>, &mdash; App User
                  </span>
                </p>

              </div>
            </div> 
        </SwiperSlide>
        <SwiperSlide>
          
        <div className="testimonial-swiper-slide">
              <div className="review text-center">
                <p className="stars">
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill muted"></span>
                </p>
                <h3>(Testimonials Coming Soon)</h3>
                <blockquote>
                  <p>...</p>
                </blockquote>

                <p className="review-user">
                  <img src={TestimonialPhoto2} alt="user-review" className="img-fluid rounded-circle mb-3" />
                  <span className="d-block">
                    <span className="text-black">Johan Smith</span>, &mdash; App User
                  </span>
                </p>

              </div>
            </div>

        </SwiperSlide>
        <SwiperSlide>
          
        <div className="testimonial-swiper-slide">
              <div className="review text-center">
                <p className="stars">
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill"></span>
                  <span className="bi bi-star-fill muted"></span>
                </p>
                <h3>(Testimonials Coming Soon)</h3>
                <blockquote>
                  <p>...</p>
                </blockquote>

                <p className="review-user">
                  <img src={TestimonialPhoto3} alt="user-review" className="img-fluid rounded-circle mb-3" />
                  <span className="d-block">
                    <span className="text-black">Jean Thunberg</span>, &mdash; App User
                  </span>
                </p>
              </div>
            </div>
        </SwiperSlide>
      </Swiper>
  </div>
</section> */}

{/* <!-- End Testimonials Section --> */}

{/* <!-- ======= CTA Section ======= --> */}
<section className="section cta-section">
    <div className="cta-container">
      <div className="cta-text">
        <h3>Improve Your Security<br></br>...Get the App Today!</h3>
      </div>
      <div className="badges-container">
      <div className="google-badge-container">
      <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a>
      {<img src={LinkTreeQRCode} className="qr-code" alt="app-badge" /> }
      </div>
      <div className="apple-badge-container">
       {/* <!--  <p><a href="#" className="btn d-inline-flex align-items-center"><i className="bx bxl-apple"></i><span>App store</span></a>  </p> --> */}
       {/* <a href="https://www.apple.com/ca/app-store/" > <img src={AppleAppBadge} className="appple-app-badge" alt="app-badge" /> </a> */}
       {/* <img src={AppleQRCode} className="qr-code" alt="app-badge" /> */}
      </div>
      </div>
    </div>
  
</section>{/* <!-- End CTA Section --> */}

   {/* <!-- ======= Footer ======= --> */}
   <footer className="footer" role="contentinfo">
    <div className="container">
      <div className="row">
        <div className="col-md-4 mb-4 mb-md-0 footer-nav-about footer-navigation">
          <h3>About Tethered GPS</h3>
          <p>Tethered is a mobile app providing 24/7 security monitoring. Have peace of mind, wherever you go.</p>
          <p className="social">
            <SocialLinks></SocialLinks>
          </p>
        </div>
        <div className="col-md-7 ms-auto">
          <div className="row site-section pt-0">
            <div className="col-md-4 mb-4 mb-md-0 footer-navigation">
              <h3>Navigation</h3>
              <ul className="list-unstyled">
                <li><a href="tutorials-create-a-safety-zone" >Tutorials</a></li>
                <li><a href="faq" >FAQ</a></li>
                <li><a href="contact-us" >Contact Us</a></li>
                <li><a href="jobs" >Jobs</a></li>
              </ul>
            </div>
            <div className="col-md-4 mb-4 mb-md-0 footer-navigation">
              <h3>Legal</h3>
              <ul className="list-unstyled">
                <li><a href="privacy-policy" target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                <li><a href="cookies-policy" target="_blank" rel="noopener noreferrer">Cookies Policy</a></li>
                <li><a href="terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></li>
              </ul>
            </div>
            <div className="col-md-4 mb-4 mb-md-0 footer-downloads footer-navigation">
              <h3>Downloads</h3>
              <ul className="list-unstyled">
                <li><a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer">App from the Play Store</a></li>
                {/* <li>Available Soon In The App Store...</li> */}
                <li><a href="https://drive.google.com/drive/folders/1AarQ0E2yypl7WGEpq7zU-XfaNDF7UGzl?usp=sharing" target="_blank" rel="noopener noreferrer">Media Kit</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-center text-center">
        <div className="col-md-7">
          <p className="copyright">&copy; 2023 DECO STICMIRAC All rights reserved. <br />  </p>
          {/* Icon made by <a href="https://www.flaticon.com/authors/freepik" target="_blank" rel="noopener noreferrer">Freepik</a> from <a href="https://www.flaticon.com/" target="_blank" rel="noopener noreferrer"> www.flaticon.com</a> */}
          <div className="credits">
          </div>
        </div>
      </div>
    </div>
  </footer>

 {/* <!-- Testimonial Slider JS File --> */}
 <script src="scripts/testimonials.js"></script>

</div>
  
      </div>
    )
  }