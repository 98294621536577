import { useState } from 'react'
import { createContext, useReducer, useEffect } from 'react'
import { projectAuth } from '../firebase/config'
// import { sendPasswordResetEmail } from 'firebase/compat/auth'

export const AuthContext = createContext()

export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, user: action.payload }
    case 'LOGOUT':
      return { ...state, user: null }
    case 'AUTH_IS_READY':
      return { user: action.payload, authIsReady: true }
    default:
      return state
  }
}

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
    authIsReady: false
  })
  
  const [logged, setLogged] = useState(false)

  useEffect(() => {
    const unsub = projectAuth.onAuthStateChanged(user => {
      dispatch({ type: 'AUTH_IS_READY', payload: user })
      unsub()
    })
  }, [])

  useEffect(() => {
    // console.log("🚀 ~ file: AuthContext.js:36 ~ useEffect ~ window.location", window.location)
    let token = window.location.search.split("=")[1];
    if (token === "ANXNmBettCJmkKf0ULuaQKSXmvJ9J1AGHJwfpQoKBXU8qiTmvXgqr6obcdNO") {
      setLogged(true)
    } else {
      setLogged(false)
    }
  }, [window.location.search])

  // console.log('AuthContext state:', state)

  // const forgotPassword = (email) => {
  //   return sendPasswordResetEmail(auth, email)
  // }
  
  return (
    <AuthContext.Provider value={{ ...state, dispatch, logged }}>
      {children}
    </AuthContext.Provider>
  )

}