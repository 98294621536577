import TutorialNavbar from '../../components/TutorialNavbar.js'

// styles
import './Tutorials.css'

// images import 
import Image1 from '../../assets/img/tutorial-screenshots/create-a-geofence/step1-login-screen.png'
import Image2 from '../../assets/img/tutorial-screenshots/create-a-geofence/step2-geofence.png'
import Image3 from '../../assets/img/tutorial-screenshots/create-a-geofence/step3-safetyzone.png'
import Image4 from '../../assets/img/tutorial-screenshots/create-a-geofence/step4-safetyzone2.png'
import Image5 from '../../assets/img/tutorial-screenshots/create-a-geofence/step5-allow.png'
import Image6 from '../../assets/img/tutorial-screenshots/create-a-geofence/step6-address.png'
import Image7 from '../../assets/img/tutorial-screenshots/create-a-geofence/step7-checkAddress.png'
import Image8 from '../../assets/img/tutorial-screenshots/create-a-geofence/step8-confirm.png'

export default function TutorialCreateSafetyZone() {

  return (
    <div>

        {/* <!-- ======= Hero Section ======= --> */}
        <section className="hero-section" id="hero">

          <div className="wave">

            <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                  <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
                </g>
              </g>
            </svg>

          </div>

          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 hero-text-image">
                <div className="row">
                  <div className="col-lg-8 text-center text-lg-start">
                    <h1 data-aos="fade-right">TETHERED GPS Tutorials. <br /> </h1>

                    <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Simple Step-By-Step Guides.</p>
                    {/* <!--   <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="#get-started" className="btn btn-outline-white">Get Started</a></p> --> */}
                  </div>   
                </div>
              </div>
            </div>
          </div>

        </section> {/* <!-- End Hero --> */}

        {/* Tutorial Navbar Import */}
        <TutorialNavbar />

        <main id="main">

          {/* <!-- ======= Tutorial Section ======= --> */}

          {/* <!-- ======= Tutorial Create a Geofence (Safety Zone) ======= --> */}
          <section className="section" id="get-started">

            <div className="container tutorial-flex" id="create-a-geofence" >

              <div className="row justify-content-center tutorial-top-container" data-aos="fade">

                <div className="tutorial-container">

                  <h2 className="section-heading">Create a Safety Zone</h2>

                  <div className="tutorial-content">
                   
                    <div className="step tutorial-text-box">

                      <h3>Purpose:</h3>
                      <p>The fastest way for us to be alerted about a possible security situation, is getting an alert that one of our members has been taken outside their Safety Zone. A Safety Zone is a geographic area that you’ve indicated is safe (around your home, your work, your family, your travel destination etc.).
                        <br /><br />
                        You can create multiple safety zones. We recommend that you create safety zones for every place that you regularly visit.
                      </p>

                      <br />
                      <h3>Step 1:</h3>
                      <p>Login to Tethered App using your email and password.</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image1} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Step 2: </h3>
                      <p>Click the Safety Zone icon in the footer navigation.</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image2} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Step 3: </h3>
                      <p>Click the green Add new Safety Zone button</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">

                    <img src={Image3} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3> Step 4: </h3>
                      <p>Select your desired safe zone radius (5km, 10km, etc.). then click the select location button</p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image4} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Step 5:</h3>
                      <p>If prompted, allow Tethered to access devices location. In order to function and provide you full protection, Tethered needs access to your device's location at all times. </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image5} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Step 6:</h3>
                      <p>a) Start typing the name of your address (Home address, Business address, travel destination, anywhere you regularly visit, etc.), b) and then click the correct address. </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image6} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Step 7:</h3>
                      <p>Confirm your address is correct, then hit the back button.  </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image7} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Step 8:</h3>
                      <p>Confirm your location and radius are correct. If so, you’re done! If you have more locations to add, simply repeat steps 3-8.   </p>
                    </div>
                  </div>

                  <div className="tutorial-img-container">
                    <img src={Image8} alt="icon" className="img-fluid tutorial-img" />
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Finished!</h3>
                      <p> Any issues with setup? Contact us at  <a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a> </p>
                    </div>
                  </div>

                  <div className="tutorial-content">

                    <div className="step tutorial-text-box">

                      <h3>Next Steps:</h3>
                      <p> Continue your app setup by completing the next tutorial to  <a href="/tutorials-create-a-contact">Create A Contact</a> </p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>

        </main> {/* <!-- End #main --> */}

        {/* <!-- ======= JS Files Import ======= --> 
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}

        {/* <!-- Vendor JS Files --> */}
        <script src="../vendor/aos/aos.js"></script>
        <script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
        <script src="../vendor/swiper/swiper-bundle.min.js"></script>
        <script src="../vendor/php-email-form/validate.js"></script>

        {/* <!-- Template Main JS File --> */}
        <script src="../scripts/main.js"></script>
        <script src="../scripts/accordion.js"></script>

    </div>
  )
}
