import './FAQ.css'
import Accordion from 'react-bootstrap/Accordion';

export default function FAQ() {
  return (
    <div>

{/* <!-- ======= Hero Section ======= --> */} 
<section className="hero-section" id="hero">

  <div className="wave">

    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>

  </div>

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">Have Questions? <br />
              We Have Answers.</h1>
              
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Get answers to frequently asked questions about Tethered GPS.</p> 
             {/* <!-- <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="#get-started" className="btn btn-outline-white">Get Started</a></p> --> */} 
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

<main id="main">

 {/* <!-- ======= Home Section ======= --> */} 
  <section className="section faq">
    <div className="container">

      <div className="row justify-content-center text-center mb-5">
        <div className="col-md-5" data-aos="fade-up">
          <h2 className="section-heading faq-h2" id="get-started">FAQ:</h2>
          <p> (Click each question to expand)</p>
          
        </div>
      </div>

    </div>
  </section>

<br />

 {/* <!-- FAQ Section --> */}
 
<section className="faq-container" id="about">
  <div className="container">
    <div className="accordion" id="accordionExample">
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header><strong> What is Tethered GPS? </strong></Accordion.Header>
        <Accordion.Body>
        Tethered is a security monitoring mobile application that allows
        you to secretly alert the authorities 
        in the event of a kidnapping. When 
        every second counts, get help fast!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header><strong>How does Tethered work? </strong></Accordion.Header>
        <Accordion.Body>
        Set your location and safety zone in the 
        app. In the event you're suddenly outside 
        your safety zone, or if you haven't checked 
        in recently, or you've initiated a panic call, one of our security monitors will 
        be alerted to a possible emergency, and contact 
        will be made to determine your status.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header><strong>What is a panic situation? </strong></Accordion.Header>
        <Accordion.Body>
        Tethered has a built in panic feature, where users can alert
        our security monitors that they're in an emergency situation and need immediate help.
        The panic button is a bright red button in the bottom right corner of the app.
        Once clicked, the user will be asked to slide the panic icon from the left
        to the right side of the screen. The sliding action is built to 
        prevent accidental panic activation. Once panic is initiated, remain calm.
        One of our security monitors will initiate contact protocols to determine your status
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="3">
        <Accordion.Header><strong>Who is Tethered for? </strong></Accordion.Header>
        <Accordion.Body>
        Tethered is for individuals, families and 
        enterprises, where <span className="bold">'Kidnap for Ransom'</span> is a concern.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="4">
        <Accordion.Header><strong>Does Tethered prevent kidnappings? </strong></Accordion.Header>
        <Accordion.Body>
        Unfortunately Tethered cannot prevent 
        kidnappings. However, having Tethered downloaded 
        on your smartphone gives you a powerful tool to 
        facilitate a faster and safer recovery. 
        With that said, the growing community wide 
        adoption of Tethered may act as an 
        overall deterrent for kidnappings, 
        making them too risky for kidnappers,
        and forcing them to think twice. 
        The more we join together, the more power we have as a community to fight the scourge of kidnapping. 
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="5">
        <Accordion.Header><strong>Where does Tethered work? </strong></Accordion.Header>
        <Accordion.Body>
        Currently available in Nigeria only.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="6">
        <Accordion.Header><strong>How much does Tethered cost? </strong></Accordion.Header>
        <Accordion.Body>
        Tethered for individuals starts at $10USD/mo, 
        and we offer custom pricing for enterprise
        level solutions.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="7">
        <Accordion.Header><strong>Is Tethered available for iOS and Android?  </strong></Accordion.Header>
        <Accordion.Body>
        Currently available on Android only
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="8">
        <Accordion.Header><strong>Can I still travel while using Tethered? </strong></Accordion.Header>
        <Accordion.Body>
        Yes! Before travelling, ensure you 
        update your safety zone, to include the 
        area you're traveling to in Nigeria, and 
        your means of travel.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="9">
        <Accordion.Header><strong>Will my employer be able to track me? </strong></Accordion.Header>
        <Accordion.Body>
        No, Tethered does not share monitoring or location data with 
        anyone until a security or panic event occurs. If a security 
        event occurs data will be shared with your listed emergency 
        contacts based on that data sharing settings you have selected 
        in the Tethered settings menu.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="10">
        <Accordion.Header><strong>What do I need to get started? </strong></Accordion.Header>
        <Accordion.Body>
        An Android smartphone, download the Tethered App, 
        choose your membership plan and you're ready to go!
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="11">
        <Accordion.Header><strong>What do I do in an emergency? </strong></Accordion.Header>
        <Accordion.Body>
        If you have an emergency and it is safe to do so, 
        activate the red panic button in the app. If it's 
        not safe to do so, remain calm. As soon as Tethered 
        identifies you've left your safety zone, or have not checked in, our security 
        monitors will initiate emergency response protocols.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="12">
        <Accordion.Header><strong>What happens if I accidentally go outside my safety zone? </strong></Accordion.Header>
        <Accordion.Body>
        If you accidentally go outside your safety zone, 
        a security monitor will attempt to contact you with 
        instructions to confirm your status.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="13">
        <Accordion.Header><strong>What happens if I lose or change my phone? </strong></Accordion.Header>
        <Accordion.Body>
        Please notify us immediately, if you lose your phone, so 
        that we know you're safe! You can do this through the
        Contact Us form on www.tetheredgps.com. Then 
        we'll walk you through the steps of 
        getting Tethered GPS setup on your new device.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="14">
        <Accordion.Header><strong>Can I pause the service while still keeping my membership active? </strong></Accordion.Header>
        <Accordion.Body>
        Yes. If you're traveling outside of Nigeria or just 
        taking a short trip outside your safety zone
        , you 
        can pause reporting until you return to your safety 
        zone. We also offer limited service outside of Nigeria. 
        For more information, please contact our Customer Care 
        Team for assistance.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="15">
        <Accordion.Header><strong>I don't have a phone plan, will this app work on wifi only? </strong></Accordion.Header>
        <Accordion.Body>
        No. You'll need to have a phone plan that can 
        receive an SMS text message to verify your account.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="16">
        <Accordion.Header><strong>What happens if I've been kidnapped and my phone runs out of data? </strong></Accordion.Header>
        <Accordion.Body>
        We have two recommendations to prevent this from happening. First, setup your phone plan to auto-renew your data every month.
        Second, let your family know your phone plan details so they can renew your data on your behalf should the situation arise. 
        </Accordion.Body>
      </Accordion.Item>

    </Accordion>
</div>
</div>
</section>

</main>{/* <!-- End #main --> */}

 {/* <!-- Accordion JS File --> */} 
  
  <script src="scripts/accordion.js"></script>

    </div>
  )
}
 