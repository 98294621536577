import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import { HeaderScroll } from "./scripts/headerScroll.js"
import { StatsCounter } from "./scripts/stats-counter.js"
import { HamburgerNav } from "./scripts/hamburgerNav.js"
import { useEffect, useState } from 'react';
import React from 'react';

// styles
import './App.css'

// Vendor CSS Files
// import './vendor/aos/aos.css'
import "./vendor/bootstrap/css/bootstrap.css"
import "./vendor/bootstrap-icons/bootstrap-icons.css"
import "./vendor/boxicons/css/boxicons.min.css"
import "./vendor/swiper/swiper-bundle.min.css"

// pages and componeents
import Homepage from './pages/homepage/Homepage'
import Pricing from './pages/pricing/Pricing'
import Login from './pages/login/Login'
import ForgotPassword from './pages/forgot-password/ForgotPassword'
import ContactUs from './pages/contact-us/Contact-Us'
import AboutUs from './pages/about-us/About-Us'
import Jobs from './pages/jobs/Jobs'
import FAQ from './pages/faq/FAQ'
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy'
import CookiesPolicy from './pages/cookies-policy/CookiesPolicy'
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions'
import Signup from './pages/signup/Signup'
import AmericanEnterprise from './pages/american-enterprise/American-Enterprise'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import TutorialCreateSafetyZone from './pages/tutorials/Tutorial-create-a-geofence'
import TutorialCreateContact from './pages/tutorials/Tutorial-create-a-contact'
import TutorialAddDocument from './pages/tutorials/Tutorial-add-a-document'
import TutorialActivatePanicButton from './pages/tutorials/Tutorial-activate-panic'
import TutorialRespondToAlert from './pages/tutorials/Tutorial-respond-to-alert'
import TutorialHowToHideApp from './pages/tutorials/Tutorial-how-to-hide-app'

function App() {
  const { user, authIsReady } = useAuthContext()
  const [logged, setLogged] = useState(false)
  
  useEffect(() => {
    let token = window.location.search.split("=")[1];
    if (token === "ANXNmBettCJmkKf0ULuaQKSXmvJ9J1AGHJwfpQoKBXU8qiTmvXgqr6obcdNO") {
      setLogged(true)
    } else {
      setLogged(false)
    }
  }, [window.location.pathname])

  return (
    <div className="App">

      {HeaderScroll()}
      {/* {StatsCounter()} */}
      {HamburgerNav()}

      {authIsReady && (

        <BrowserRouter>

          <div className="app-container">

            <Navbar />
            
            <Switch>
              {/* PAGES AVAILABLE TO ALL USERS: */}
              <Route exact path="/">
                {/* {!user && <Redirect to="/login" />} */}
                {user && <Homepage />}
                {!user && <Homepage />}
              </Route>

              <Route exact path="/pricing">
                {user && <Pricing />}
                {!user && <Pricing />}
              </Route>

              <Route exact path="/contact-us">
                {user && <ContactUs />}
                {!user && <ContactUs />}
              </Route>

              <Route exact path="/jobs">
                {user && <Jobs />}
                {!user && <Jobs />}
              </Route>

              <Route exact path="/about-us">
                {user && <AboutUs />}
                {!user && <AboutUs />}
              </Route>

              <Route exact path="/faq">
                {user && <FAQ />}
                {!user && <FAQ />}
              </Route>

              <Route exact path="/privacy-policy">
                {user && <PrivacyPolicy />}
                {!user && <PrivacyPolicy />}
              </Route>

              <Route exact path="/cookies-policy">
                {user && <CookiesPolicy />}
                {!user && <CookiesPolicy />}
              </Route>

              <Route exact path="/terms-and-conditions">
                {user && <TermsAndConditions />}
                {!user && <TermsAndConditions />}
              </Route>

              <Route exact path="/american-enterprise">
                {user && <AmericanEnterprise />}
                {!user && <AmericanEnterprise />}
              </Route>

              {/* PAGES FOR LOGGED IN MEMBERS ONLY: */}
              
              <Route path="/tutorials-create-a-safety-zone">
                {(!logged && !user ) && <TutorialCreateSafetyZone />}
                {(logged || user ) && <TutorialCreateSafetyZone />}
              </Route>

              <Route path="/tutorials-create-a-contact">
                {(!logged && !user ) && <TutorialCreateContact />}
                {(logged || user ) && <TutorialCreateContact />}
              </Route>

              <Route path="/tutorials-add-a-document">
                {(!logged && !user ) && <TutorialAddDocument />}
                {(logged || user ) && <TutorialAddDocument />}
              </Route>

              <Route path="/tutorials-activate-panic-button">
                {(!logged && !user ) && <TutorialActivatePanicButton />}
                {(logged || user ) && <TutorialActivatePanicButton />}
              </Route>

              <Route path="/tutorials-respond-to-alert">
                {(!logged && !user ) && <TutorialRespondToAlert />}
                {(logged || user ) && <TutorialRespondToAlert />}
              </Route>

              <Route path="/tutorials-how-to-hide-the-app">
                {(!logged && !user ) && <TutorialHowToHideApp />}
                {(logged || user ) && <TutorialHowToHideApp />}
              </Route>

              {/* The LOGIN and SIGNUP Text Boxes */}
              <Route path="/login">
                {user && <Redirect to="/" />} {/* Sets the destination AFTER login successful: */}
                {!user && <Login />}
              </Route>

              <Route path="/forgot-password">
                {user && <Redirect to="/" />} {/* Sets the destination AFTER login successful: */}
                {!user && <ForgotPassword />}
              </Route>

              {/* The LOGIN and SIGNUP Text Boxes */}
              <Route path="/signup">
                {user && <Redirect to="/" />} {/* Sets the destination AFTER login successful: */}
                {!user && <Signup />}
              </Route>

            </Switch>

            <Footer />

          </div>

        </BrowserRouter>
        )
      }
    </div>
  );
}

export default App
