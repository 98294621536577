import {useLogout} from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import './Navbar.css'
import Logo from '../assets/img/tethered_banner-white.png'

export default function Navbar() {

  const {logout, isPending} = useLogout()
  const { user } = useAuthContext()

  return (
    <div className="navbar fixed-top " id="header">
        <div className ="logo">
                <a href="/"><img src = {Logo} alt ="logo" /></a>
                <span>  </span>
            </div>
        
        <ul id="close-nav">
            
            <li><a href="/pricing">Pricing</a></li>
            <li><a href="/contact-us">Contact Us</a></li>
            <li><a href="/about-us">About Us</a></li>
            <li><a href="/faq">FAQ</a></li>
            <li><a href="/tutorials-create-a-safety-zone" >Tutorials</a></li>
            <li className="big-login-button">
              {!user && <a href="/login" >Login</a> }
            </li>
            <li>
              {!user && <a href="/signup" className="btn-react login-button">Sign Up</a> }
            </li>
            <li>
               {user && !isPending && <button className="btn-react login-button" onClick={logout}>Logout</button> }
               {user && isPending && <button  className="btn-react login-button" disabled>Logging Out</button> }
            </li>
        </ul>

        <i className="bi bi-list mobile-nav-toggle" ></i>

    </div>
  )
}