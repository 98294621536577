import React, { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { projectFirestore } from "../../firebase/config";
import './Contact-Us.css';
import SocialLinks from '../../components/social-links.js'


export default function ContactUs() {
    const [user_name, setName] = useState("");
    const [user_email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [opened, setOpened] = useState("");
    const [closed, setClosed] = useState("");
    const [agent, setAgent] = useState("");
    const [status, setStatus] = useState("");
    // const [comments, setComments] = useState("");

    // Date Constructor:
    const current = new Date();
    const currentDate = `${current.toISOString()}`;

    const handleSubmit = (e) => {
      e.preventDefault();
      document.getElementById("dvMesssage").classList.remove("SuccessMessage");
      document.getElementById("dvMesssage").classList.remove("ErrorMessage");
      projectFirestore.collection('contact-us-msgs').add({
        name: user_name,
        email: user_email,
        subject: subject,
        message: message,

        opened: currentDate,
        closed: null,
        agent: {
          "agent": {
            "email": "",
            "name": {
              "title": "",
              "first": "",
              "middle": "",
              "last": "",
              "qualifier": "",
              "verified": false
            },
          },
        },
        status: "Open",
        comments: {
          "history" : [],
        },
        
      }).then(() => {
        document.getElementById("dvMesssage").className = "SuccessMessage";
        document.getElementById("dvMesssage").innerHTML = 'Your message as been sent. A Tethered, GPS team member will contact you within 24 hours.'
        
      }).catch((error) => {
        document.getElementById("dvMesssage").className = "ErrorMessage";
        document.getElementById("dvMesssage").innerHTML=error.message;
      });
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setOpened("");
      setClosed("");
      setAgent("");
      setStatus("");
      // setComments("");
    };
       
    var enableSend = function handleOnChange(value) {
      const btn = document.getElementById('sendBtn');
      btn.setAttribute('disabled', '')
      document.getElementById("dvMesssage").classList.remove("SuccessMessage");
      document.getElementById("dvMesssage").classList.remove("ErrorMessage");

      if (value != null) {
        //end able send button
        btn.removeAttribute('disabled')
      }
    }   

  return (
    <div>

{/* <!-- ======= Hero Section ======= --> */} 
 <section className="hero-section" id="hero">
  <div className="wave">
    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>
  </div>

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">Need to talk? <br /> We're here.<br /> Contact us below.</h1>
             
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Get support, get your questions answered.</p>  
          </div>
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

<main id="main">

 {/* <!-- ======= Home Section ======= --> */} 
  <section className="section" id="get-started">
    <div className="container">
      <div className="row justify-content-center text-center mb-5">
        <div className="contact-form-container" data-aos="fade-up">
            <div className="">
                                
                <h2 className="section-heading">Contact Us</h2>
                <div className="row">
                    <div className="col-md-7 col-sm-12">
                                         
                      <form onSubmit={handleSubmit} className="flex-center dir-column" style={{ margin: "25px 30px 75px 100px" }} >
                        <div id="dvMesssage" style={{ display: "none" }}></div>
                        <input type="text" className="form-control" placeholder="Full Name" name="user_name" required value={user_name} onChange={(e) => setName(e.target.value)} />
                        <br />
                        <input type="email" className="form-control" placeholder="Your Email Address" name="user_email" required value={user_email} onChange={(e) => setEmail(e.target.value)} />
                        <br />
                        <input type="text" className="form-control" placeholder="Subject" name="subject" required value={subject} onChange={(e) => setSubject(e.target.value)} />
                        <br />
                        <textarea className="" name="message" required cols="30" row="10" placeholder="Your Message" style={{ border: "1px solid #ced4da" }}   value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                        <br />
                        
                          {/* ReCaptcha code for LIVE WEBSITE */}
                          <ReCAPTCHA id="botCheck"
                            sitekey="6LdWsdYkAAAAAO2h1bGaTKDuukIPffrKFL0phRLv"
                            onChange={enableSend}
                          />

                          {/* ReCaptcha code for LOCAL HOST */}
                          {/* <ReCAPTCHA id="botCheck"
                            sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                            onChange={enableSend}
                          /> */}

                        <br />
                        <button id="sendBtn" disabled type="submit" className="form-control btn btn-primary">Send Message</button>
                      </form>
                    </div>

                    <div className="col-md-5 col-sm-12" style={{ textAlign: "left" }}>
                      {/* <p className="contact-list"><span className="list-icon bi bi-geo-alt"><strong className='contact-subtitle'>Address</strong></span><br />
                      <span>Fake Roadway, Jabi 900108, Abuja, Nigeria</span>
                      </p> */}
                      <p className="contact-list"><span className="list-icon bi bi-telephone"><strong className='contact-subtitle'>24/7 Telephone Support</strong></span><br />
                      <span>+234-806-800-4993</span>
                      </p>
                      <p className="contact-list"><span className="list-icon bi bi-chat-left-dots"><strong className='contact-subtitle'>Email Support</strong></span><br />
                      <span><a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a></span>
                      </p>
                      <div className="contact-list social"><span className="list-icon bi bi-link"><strong className='contact-subtitle'>Follow Us</strong></span><br />
                        <SocialLinks></SocialLinks>
                      </div>

                    </div>
            </div>
        </div>


           {/* 
           <!-- 
            <form className="user-contact-form" action="../forms/contact.php" method="post">

              <div className="double-column">
              <input type="text" id="inboud-lead-name" name="inboud-lead-name" placeholder="Name *"> 
              <input type="email" id="inboud-lead-email" name="inboud-lead-email" placeholder="Email *">
              <input type="text" id="inboud-lead-time-zone" name="inboud-lead-time-zone" placeholder="Timezone *">
              <input type="text" id="inboud-lead-country" name="inboud-lead-country" placeholder="Country *">
              <input type="text" id="inboud-lead-question"  name="inboud-lead-question" placeholder="Your question... *"> 
              <input type="submit" value="Submit " id="user-submit">
            </div>
              </form>
              -->
           */}

        </div>
      </div>
    </div>
  </section>

</main>{/* <!-- End #main --> */}

    </div>
  )
}
 
