// styles
import './Login.css'

import {useState} from 'react'
import {useLogin} from '../../hooks/useLogin'
import Logo from '../../assets/img/tethered_banner.png'


export default function Login() {

const [email, setEmail] = useState('')
const [password, setPassword] = useState('')
const {login, isPending, error, forgotPassword} = useLogin()

const handleSubmit = (e) => {
  e.preventDefault()
  login(email, password)
}

// const forgotPasswordHandler = (e) => {
//   e.preventDefault()
//   forgotPassword(email).then(() => email.value = "")
// }


// const forgotPasswordHandler = () => {
//   const email = email.value
//   if(email) forgotPassword(email).then(() => email.value = "")
// }

  return (
  <div>  
     {/* background section */}
  <section className="background-login"> 

    <form className="auth-form" onSubmit={handleSubmit}>
 
      <div className ="login-logo">
                <img src = {Logo} alt ="logo" />
                <span>  </span>
            </div>
      <br></br>
      <label>
        <span>Email: </span>
      <input 
        required 
        type="email" 
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        /> 
      </label>
      <label>
      <span>Password: </span>
      <input 
        required 
        type="password" 
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        /> 
        
      </label>
      <br></br>
        {!isPending && <button id="sendBtn" type="submit" className="form-control btn btn-primary">Login</button> } 
        {isPending && <button className="form-control btn btn-primary" disabled>loading</button> }
        {error && <div className="error">{error}</div>}
        <br></br>
        {/* <button href="/forgot-password">Forgot Password?</button> */}
        <a href="/forgot-password" className="forgot-password" >Forgot Password?</a>
        {/* <button onClick={forgotPasswordHandler}>Forgot Password?</button> */}
    </form>

  </section> 
  </div>
  )
}




 