// styles
import './About-Us.css'

// images import 
import Phone1 from '../../assets/img/tethered-phone-img-1-compress2.webp'
import Phone2 from '../../assets/img/tethered-phone-img-2-removebg-preview.png'
import Phone3 from '../../assets/img/tethered-phone-img-4-removebg-preview.png'
import Phone4 from '../../assets/img/tethered-phone-img-3-removebg-preview.png'
import TeamJeff from '../../assets/img/Jeff.PNG'
import TeamSteve from '../../assets/img/SteveOkwori2.jpg'
import TeamGabriel from '../../assets/img/Gabriel Hill.jpg'
import TeamNik from '../../assets/img/headshot-Nik-Morse.jpeg'
import TeamGreg from '../../assets/img/FGregMorris.JPG'
import TeamJimena from '../../assets/img/Jimena.jpg'
import TeamLaura from '../../assets/img/headshot-laura-rodriguez-square.jpg'
import TeamArthur from '../../assets/img/arthur_headshot_bowtie.jpg'

export default function AboutUS() {
  return (
    <div>

 {/* <!-- ======= Hero Section ======= --> */} 
 <section className="hero-section" id="hero">
  <div className="wave">
    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>
  </div>

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">The Mission.<br />The App.<br />The Team. </h1>
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">What Tethered GPS is all about.</p>  
          </div>
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

<main id="main">

 {/* <!-- The Mission Section --> */} 
  <section className="section" id="get-started">
    <div className="container">
      <div className="row justify-content-center text-center mb-5">
        <div className="col-md-5" data-aos="fade-up">
          <h2 className="section-heading">The Mission</h2>
        </div>
      </div>
      <div className="row mission-container">
        <div className="mission-box" data-aos="fade-up" data-aos-delay="">
          <div className="feature-1 text-center mission-text">
            <h2 className="mission-statement">Improve community security by offering advanced, reliable and affordable GPS technology, coupled with unmatched security monitoring services.</h2>
          </div>
        </div>
      </div>
    </div>
  </section>

 {/* <!-- The App Section --> */}

  <section className="section">
    <div className="container">
      <div className="row justify-content-center text-center mb-5 home-section">
        <div className="col-md-5" data-aos="fade-up">
          <h2 className="section-heading about-h2">The App</h2>
        </div>
      </div>

      <div className="row align-items-center home-section">
        <div className="col-md-4 me-auto home-power">
          <h3 className="mb-4 about-h3">24/7 Security Monitoring And Support </h3>
          <p className="mb-4"> With Tethered in your pocket, you have access to 24/7 Security Monitoring. <br /> <br />
          No matter the time of day, we're there for you, when you need us most. 
          </p>

        </div>
        <div className="col-md-6 home-images" data-aos="fade-left">
          <img src={Phone1} alt="phone" className="img-fluid img-about mobile-off" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4 about-h3">Peace Of Mind </h3>
          <p className="mb-4">Tethered keeps you connected to your family, friends, organization or government agency when it matters most. <br /> <br />

            Access to sophisticated 21st century technology with pinpoint location accuracy 24/7. <br /> <br />
            
            Alert others immediately for assistance when you have an emergency. <br /> <br />
            
            The Tethered Support Team helps you to be more vigilant and reduce your vulnerability.</p>
        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={Phone2} alt="phone" className="img-fluid img-about" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 me-auto home-power">
          <h3 className="mb-4 about-h3">Keep Your Most Important Contacts Close</h3>
          <p className="mb-4">During an emergency, the last thing you want to be worried about is how to find your most important emergency contacts. <br /> <br />
          With Tethered, upload all of your most important contacts and have the peace of mind they're being kept in a secure place. <br /> <br /> 
          If an emergency situation arises, we'll be able to reach out to your contacts to keep them informed or seek their help.
          </p>
        </div>
        <div className="col-md-6 home-images" data-aos="fade-left">
          <img src={Phone3} alt="phone" className="img-fluid img-about mobile-off" />
        </div>
      </div>
    </div>
  </section>
  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4 about-h3">Protection In A Panic </h3>
          <p className="mb-4"> Whether walking home from work, or driving out to visit  family, feel safe knowing you've got Tethered in your pocket. <br /> <br />
          
          If a situation arises where you feel unsafe or that you and your family could be in danger, Tethered has a panic mode. <br /> <br />
        
        Instantly get connected with a Security Monitor to enhance your protection.</p>  
        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={Phone4} alt="phone" className="img-fluid img-about" />
        </div>
      </div>
    </div>
  </section>

{/* <!-- The Team Section --> */} 
<section className="section">
<div className="container">
  <div className="row justify-content-center text-center mb-5">
    <div className="col-md-5" data-aos="fade-up">
      <h2 className="section-heading">The Team</h2>
    </div>
  </div>

  <div className="row">
    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamJeff} alt="team"/>
        </div>
        <h3 className="mb-3">Dr. Jeffrey Torain, CPP, CCAP</h3>
        <p>Managing Director <br /> DECO GPS LLC</p>
      </div>
    </div>

    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamSteve} alt="team"/>
        </div>
        <h3 className="mb-3">Stephen Obochi Okwori</h3>
        <p>Managing Director <br /> Sticmirac International Ltd.</p>
      </div>
    </div>

    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamGabriel} alt="team"/>
        </div>
        <h3 className="mb-3">Gabriel L. Hill</h3>
        <p>Director <br /> DECO GPS LLC</p>
      </div>
    </div>

    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamNik} alt="team"/>
        </div>
        <h3 className="mb-3">Nikolas Morse</h3>
        <p>Sr. Software Engineer</p>
      </div>
    </div>

    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamGreg} alt="team"/>
        </div>
        <h3 className="mb-3">Greg Morris</h3>
        <p>Sr. UI/UX Designer</p>
      </div>
    </div>

    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamJimena} alt="team"/>
        </div>
        <h3 className="mb-3">Jimena Pérez Bazzano</h3>
        <p>Social Media Marketer</p>
      </div>
    </div>

    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamLaura} alt="team"/>
        </div>
        <h3 className="mb-3">Laura Rodriguez</h3>
        <p>Jr. Developer</p>
      </div>
    </div>

    <div className="col-md-4" data-aos="fade-up" data-aos-delay="">
      <div className="feature-1 text-center">
        <div className="team-photo">
          <img className="team-photo-img" src={TeamArthur} alt="team"/>
        </div>
        <h3 className="mb-3">Arthur Tutt</h3>
        <p>Jr. Developer</p>
      </div>
    </div>
  </div>
</div>
</section>

</main>{/* <!-- End #main --> */} 

    </div>
  )
}