// styles
import './American-Enterprise.css'


// images import 
import Phone1 from '../../assets/img/tethered-phone-img-5-compress2.webp'
import Phone2 from '../../assets/img/tethered-phone-img-1-compress2.webp'
import FlaticonAlert from '../../assets/img/icons/flaticon-alert.png'
import FlaticonMap from '../../assets/img/icons/flaticon-map-marker.png'
import FlaticonCustomerReview from '../../assets/img/icons/customer-reviews-flaticon.png'
import FlaticonAgents from '../../assets/img/icons/flaticon-security-team.png'
import NewsfeedPremiumTimesKidnapping from '../../assets/img/newsfeed/premium-times-kidnapping-snapshot-compress.webp'
import NewsfeedRansomPhoto from '../../assets/img/newsfeed/leadership-ransom-photo-compress.webp'
import NewsfeedSteveThumbnail from '../../assets/img/newsfeed/steve-thumnail-1-playbutton-compress.webp'
import NewsfeedArticle5 from '../../assets/img/newsfeed/newspaper-article-5-compress.webp'
import NewsfeedSteveThumbnail2 from '../../assets/img/newsfeed/steve-thumnail-3-playbutton-compress2.webp'
import NewsfeedArticle6 from '../../assets/img/newsfeed/newspaper-article-6-compress.webp'
import TetheredPhoneAustinPreview from '../../assets/img/Tethered_Austin_Capital-removebg-preview.png'
import TetheredTravel1 from '../../assets/img/Deco-Travel-1.png'
import PWCEmergingMarkets from '../../assets/img/pwc-emerging-markets-12-July-compress2.webp'
import TetheredOffice2 from '../../assets/img/Deco-Office-2.png'
import GooglePlayBadge from '../../assets/img/icons/google-play-badge.png'

import React from "react";
import SwiperCore, { Autoplay, Navigation, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
// import "./App.css";

SwiperCore.use([Autoplay, Navigation, EffectCoverflow, Pagination]);


export default function AmericanEnterprise() {
  
  return (

<div>

{/* <!-- ======= Hero Section ======= --> */}

<section className="hero-section hero-section-homepage" id="hero">

  <div className="wave">

    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >

      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>

  </div>

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">
              Executive Security <br />For High Risk Travel.</h1>
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">  
               Expand Markets. Reduce Costs. Stay Secure. </p>
               {/* <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
            <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="/contact-us" className="btn btn-outline-white">Contact Sales</a></p> 
            {/* Get The App */}
            <br />
          </div>
          
           <div className="col-lg-4 iphone-wrap">
            <img src={TetheredPhoneAustinPreview} alt="phone" className="phone-1" data-aos="fade-right" data-aos-delay="200" />
            <img src={Phone2} alt="phone" className="phone-2" data-aos="fade-right" />
            
          </div> 
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

<main id="main">

{/* <!-- ======= Home Section ======= --> */}
  
  <section className="section">
    <div className="container">

   

      <div className="row justify-content-center text-center mb-5">
        <div  data-aos="fade-up">
          <h2 className="section-heading home-page-h2">Turn Your Smartphone Into A Personal Security Device With 24/7 GPS Monitoring </h2>
        </div>
      </div>

      <div className="row">

        <div className="home-safety-container">
        <div className="home-safety"> 

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
          <div className="feature-1 text-center">

            <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">

              <img className="graphic-icon" src={FlaticonAlert} alt="icon"/>
            </div>
            <h3 className="mb-3">Trigger SOS Security Alerts For Immediate Assistance</h3>
            {/* <p>Take steps to improve your personal safety and security, as you move about your day, by using the latest in security monitoring technology.</p> */}
          </div>

        </div>

        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="100">
          <div className="feature-1 text-center">
            
            <div className="home-safety-box-inner">
            
            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonMap} alt="icon" />
            </div>
            <h3 className="mb-3">Create Custom Safety Zones</h3>
            {/* <p>Gain access to security and safety travel information before you make your travel arrangements.</p> */}
          </div>
        </div>
        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="200">
          <div className="feature-1 text-center">

            <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonCustomerReview} alt="icon" />
            </div>
            <h3 className="mb-3">Connect With Emergency Contacts And Security Teams</h3>
            {/* <p>Meet your duty of care by properly protecting your employees, when they travel for your organization or government agency... You need to know they are safe 24/7.</p> */}
          </div>
        </div>
        </div>

        <div className="home-safety-box" data-aos="fade-up" data-aos-delay="">
          <div className="feature-1 text-center">

          <div className="home-safety-box-inner">

            <div className="wrap-icon icon-1">
              <img className="graphic-icon" src={FlaticonAgents} alt="icon" />
            </div>
            <h3 className="mb-3">24/7 Real-Time Monitoring By Security Agents For Swift Response</h3>
            {/* <p>Gain access to 21st century technology and information to have security on your side and reduce the odds of paying costly ransoms.</p> */}
          </div>
          </div>
        </div>
        </div>
      </div>
      </div>
    </div>
  </section>



   {/* <!-- Parallax Section --> */} 
<section className="banner-american-1">
 
</section>

  <section className="section safety-section">

    <div className="container">
      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        
        <h2 className="section-heading">Protecting your safety is as easy as 1...2...3!</h2>

      </div>

      <div className="row">
        <div className="col-md-4">
          <div className="step">
            <span className="number">01</span>
            <h3>Sign Up</h3>
            <p>Download Tethered GPS from the Google Play Store and select your membership tier.<br /><br /></p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="step">
            <span className="number">02</span>
            <h3>Create Safety Zone</h3>
            <p>Create your first Safety Zone, add important Contacts and Documents. <br /><br /> </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="step">
            <span className="number">03</span>
            <h3>Stay Safe</h3>
            <p>Tethered stays with you 24/7. But always stay alert, and avoid potentially unsafe situations.<br /><br /></p>
          </div>
        </div>
        
      </div>

      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        <div className="col-md-6 mb-5 safety-section-photo">
          <img src={TetheredPhoneAustinPreview} alt="icon" className="img-fluid" />
        </div>
      </div>
    </div>

  </section>

    {/* <!-- Parallax Section --> */}
    <section className="banner2">
    </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <h2 className="section-heading" id="stats-visibility-element">Power In Your Pocket.</h2>
          <div className="me-auto home-power home-power-top " id="home-stats">
          {/* home-stats-hide */}

           {/* <div className="home-stats-container">
            <p><span id="visibilityFeedback"></span></p>
              
            <div className="numbers">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">4</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">9</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="4395216407">0</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="+395216407">%</span>
              </span>
              <br /> 
              <span className="numbers__window word__window">
              <span>Increase In Kidnapping</span>
              </span>
          
            </div>

            <p >Between 2017 and 2020 in Nigeria. Mass abductions area increasing dramatically year over year. </p>

              <div className="text-align-right">
                <p > <span className="italics source bold">- HumAngleMedia.com</span> </p>
              </div>
            <br /> 
            <p >Security forces rescued 6 of 32 people abducted at a train station in Edo state...Insecurity is one of the key issues for candidates ahead of Nigeria's general elections in February [2023] when a successor to President Muhammadu Buhari will be chosen. </p>
            <div className="text-align-right">
              <p > <span className="italics source bold">- BBC News, January 2023</span> </p>
              <br />
            </div>

          </div> */}

            {/* <div className="home-stats-container">
              
              <div className="numbers ">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">5</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">1</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="4395216407">4</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--4" data-fake="+395216407">4</span>
              </span>
              <br /> 
              <span className="numbers__window word__window">
              <span  >Abducted <br /> 2022</span>
              </span>
          
            </div>

            <p > Targeting rural dwellers and travellers across the region in what some analysts say is one of the most lucrative kidnap-for-ransom syndicates in the continent. </p>

              <div className="text-align-right">
                <p > <span className="italics source bold">- The Premium Times, Nigeria</span> </p>
              </div>

            <br /> <p>...Nigeria occupies the second position on the list of the top 10 world kidnap for ransom risk countries </p>
            
            <div className="text-align-right">
              <p > <span className="italics source bold">- Muhammad Ribadu Ayuba, June 2020</span> </p>
              <br />
            </div>

            </div> */}
           
            {/* <div className="home-stats-container third-box">
              
              <div className="numbers ">
              
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--1" data-fake="2642519073">+</span>
              </span> 
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--2" data-fake="9207186394">$</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">1</span>
              </span>
              <span className="numbers__window">
              <span className="numbers__window__digit numbers__window__digit--3" data-fake="9395216407">8</span>
              </span>
              
            <br /> 
              <span className="numbers__window word__window">
                <span  >Million USD Ransom Paid</span>
              </span>
          
          </div>
              <p >Between 2011-2020. Kidnapping for ransom is the main form of kidnapping, and they’re carefully planned, organized and executed by various organized criminal groups </p>
               <div className="text-align-right">
                <p > <span className="italics source bold">- The Conversation, June 17, 2022</span> </p>
              </div>
                <br /> */}
            {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
            {/* <a href="https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" > <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
            {/* Get The App */}
            {/* <br />
          </div> */}
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4">Why Tethered GPS?</h3>
          <p >Tethered keeps you connected to your family, friends, organization or government agency when it matters most. <br /> <br />

            Access to sophisticated 21st century technology with pinpoint location accuracy 24/7. <br /> <br />
            
            Alert others immediately for assistance when you have an emergency. <br /> <br />
            
            The Tethered Support Team helps you to be more vigilant and reduce your vulnerability.</p>
            <br />
          {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
          {/* <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
          {/* Get The App */}
        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={PWCEmergingMarkets} alt="icon" className="img-fluid img-home" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">
        <div className="col-md-4 me-auto home-power">
          <h3 className="mb-4">Mexico Travel Safety & Security</h3>
          <p > Mexico has become a hotspot for violent crime, cartel wars, and kidnappings, which demand enhanced duty of care for company personnel.  
            
            <br /> <br />
            Safeguard your employees with Tethered GPS, providing advanced mobile security technology and 24/7 monitoring.
            </p>
            <br />
          {/* <p><a href="/contact-us" className="btn btn-primary">Coming Soon...</a></p> */}
          {/* <a href="https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.tetheredgps.tethered" target="_blank" rel="noopener noreferrer"> <img src={GooglePlayBadge} className="google-play-badge" alt="app-badge" /> </a> */}
          {/* Get The App */}
        </div>
        <div className="col-md-6 home-images" data-aos="fade-left">
          <img src={TetheredTravel1} alt="icon" className="img-fluid img-home mobile-off" />
        </div>
      </div>
    </div>
  </section>

  <section className="section">
    <div className="container">
      <div className="row align-items-center home-section">

        <div className="col-md-4 ms-auto order-2 home-power">
          <h3 className="mb-4">Enterprise Solutions</h3>
          <p >If you or your team visit Mexico or other high risk regions regularly for business, be aware that travel risk management has never been more challenging. <br /> <br />

            The constant threat of violence or being kidnapped is a very real and present danger. <br /> <br />
            
            Tethered provides enterprise-grade solutions that help keep you and your employees safe. <br /> <br />
            
            From traveler monitoring to local intelligence on all geopolitical zones, Tethered GPS helps you meet your duty of care and support the health and safety of your travelling employees. <br /> <br />
            
            Let Tethered GPS Help You Meet Your Duty Of Care Today!</p>
            <br />
          <p><a href="/contact-us" className="btn btn-primary">Contact Sales</a></p>
          {/* Enterprise Solutions */}
        </div>
        <div className="col-md-6 home-images" data-aos="fade-right">
          <img src={TetheredOffice2} alt="icon" className="img-fluid img-home" />
        </div>

      </div>
    </div>
  </section>

 {/* <!-- Parallax Section --> */}
   
    <section className="banner-american-2"> 
   </section> 
  
</main>
{/* <!-- End #main --> */}

{/* <!-- Template Main JS File --> */}
<script src="scripts/stats-counter.js"></script>

{/* <!-- Newsfeed/Testimonial Slider JS File --> */}
 <script src="scripts/testimonials.js"></script>

</div>
    
  )
}
 