import TutorialNavbar from '../../components/TutorialNavbar.js'

// styles
import './Tutorials.css'

// images import 
import Image1 from '../../assets/img/tutorial-screenshots/how-to-hide-app/1-Search.png'
import Image2 from '../../assets/img/tutorial-screenshots/how-to-hide-app/2-Hidden.png'
import Image3 from '../../assets/img/tutorial-screenshots/how-to-hide-app/3a-Search3.png'
import Image4 from '../../assets/img/tutorial-screenshots/how-to-hide-app/3b-Search2.png'
import Image5 from '../../assets/img/tutorial-screenshots/how-to-hide-app/4-Hidden2.png'
import Image6 from '../../assets/img/tutorial-screenshots/how-to-hide-app/5-SearchB.png'
import Image7 from '../../assets/img/tutorial-screenshots/how-to-hide-app/6-Icon.png'

export default function TutorialHowToHideApp() {

  return (
    
<div>

{/* <!-- ======= Hero Section ======= --> */} 
<section className="hero-section" id="hero">

  <div className="wave">

    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>

  </div>

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">TETHERED GPS Tutorials. <br /> </h1>
            
             <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Simple Step-By-Step Guides.</p> 
           {/* <!--   <p data-aos="fade-right" data-aos-delay="200" data-aos-offset="-500"><a href="#get-started" className="btn btn-outline-white">Get Started</a></p> --> */} 
          </div>
        </div>
      </div>
    </div>
  </div>

</section> {/* <!-- End Hero --> */} 

{/* Tutorial Navbar Import */}
<TutorialNavbar />

<main id="main">

  {/* <!-- ======= Tutorial Section ======= --> */} 
 
  {/* <!-- ======= Tutorial HOW TO HIDE THE APP ======= --> */} 
  <section className="section">

    <div className="container" id="how-to-hide-app">
      <div className="row justify-content-center text-center mb-5" data-aos="fade">
        <div className="col-md-6 mb-5 tutorial-container">
  
          <h2 className="section-heading" >How To Hide The App</h2>

          <div className="tutorial-content">

            <div className="step tutorial-text-box">
              
              <h3>Purpose:</h3>
              <p>For security reasons, some users may want to hide the TETHERED GPS app from their phone to prevent others from knowing it is installed. The following steps detail how to hide the app. 
                <br /><br />
                We’ll cover how to hide the app on a Samsung phone in this guide. 
                If you're using different Android device <a href="https://www.youtube.com/watch?v=njyiIs1xld4"  
                target = "_blank" rel="noopener noreferrer">follow this guide here</a>
                , or if you’re on iOS <a href="https://www.youtube.com/watch?v=6oPVbvPfYCQ"  
                target = "_blank" rel="noopener noreferrer">follow this guide here.</a>
                 
                </p>
                <br />
                <h3>HOW TO HIDE THE APP ON SAMSUNG PHONES</h3>
              <br />
              <h3>Step 1:</h3>
              <p>From your phones home page, swipe up to open the app tray. From there, click the 3 dots in the top right corner where the Google search bar is, and click settings. </p>
            </div>
          </div>
  
          <div className="tutorial-img-container">
          <img src={Image1} alt="tutorial" className="img-fluid tutorial-img" />
        </div>
  
          <div className="tutorial-content">

            <div className="step tutorial-text-box">
              
              <h3>Step 2: </h3>
              <p>From the Home screen settings page that opens, scroll down and click the menu item for “Hide Apps”.</p>
            </div>
          </div>
  
          <div className="tutorial-img-container">
          <img src={Image2} alt="tutorial" className="img-fluid tutorial-img" />
        </div>
  
          <div className="tutorial-content">

            <div className="step tutorial-text-box">
              
              <h3>Step 3: </h3>
              <p>Scroll down or search for “TETHERED GPS”, and click it to hide the app, and then press "DONE". </p>
            </div>
          </div>
  
          <div className="tutorial-img-container tutorial-img-container-multiple">
  
          
          <img src={Image3} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
          <img src={Image4} alt="tutorial" className="img-fluid tutorial-img tutorial-img-multiple" />
        </div>
  
          <div className="tutorial-content">
   
            <div className="step tutorial-text-box">
              
              <h3> Step 4: </h3>
              <p>The icon for TETHERED GPS should now show in the Hidden Apps tray.</p>
            </div>
          </div>
  
          <div className="tutorial-img-container">
          <img src={Image5} alt="tutorial" className="img-fluid tutorial-img" />
        </div>
  
          <div className="tutorial-content">

            <div className="step tutorial-text-box">
              
              <h3>Step 5:</h3>
              <p>Now to open TETHERED GPS, go to your phones home screen  and start typing ‘TETHERED GPS’ into the Google search bar.  </p>
            </div>
          </div>
  
          <div className="tutorial-img-container">
          <img src={Image6} alt="tutorial" className="img-fluid tutorial-img" />
        </div>
  
          <div className="tutorial-content">

            <div className="step tutorial-text-box">
              
              <h3>Step 6:</h3>
              <p>See the  TETHERED GPS icon and click it to open the app.  </p>
            </div>
          </div>
  
          <div className="tutorial-img-container">
          <img src={Image7} alt="tutorial" className="img-fluid tutorial-img" />
        </div>

        <div className="tutorial-content">
          
          <div className="step tutorial-text-box">

            <h3>Finished!</h3>
            <p> Any issues with setup? Contact us at  <a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a> </p>
          </div>
        </div>
  
        </div>
      </div>
  
     
      </div>
    
  
  </section>

</main> {/* <!-- End #main --> */} 

{/* <!-- ======= JS Files Import ======= --> 
<a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}

{/* <!-- Vendor JS Files --> */} 
<script src="../vendor/aos/aos.js"></script>
<script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
<script src="../vendor/swiper/swiper-bundle.min.js"></script>
<script src="../vendor/php-email-form/validate.js"></script>

{/* <!-- Template Main JS File --> */} 
<script src="../scripts/main.js"></script>
<script src="../scripts/accordion.js"></script>



</div>

  )
}
 