// styles
import './Pricing.css'
import React from 'react';
// import LoadOnce from "../../scripts/loadOnce.js"
import Shield from "@mui/icons-material/ShieldOutlined";
import PoliceShield from "@mui/icons-material/LocalPoliceOutlined";
import FamilyGold from "@mui/icons-material/Diversity1Outlined";

export default function Pricing() {
  // LoadOnce()

  return (
    <div>

  {/* <!-- ======= Hero Section ======= --> */}
<section className="hero-section" id="hero">

  <div className="wave">

    <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
          <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
        </g>
      </g>
    </svg>

  </div>

  <div className="container">
    <div className="row align-items-center">
      <div className="col-12 hero-text-image">
        <div className="row">
          <div className="col-lg-8 text-center text-lg-start">
            <h1 data-aos="fade-right">Pricing Plans <br />
              </h1>

              <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Whether you're an individual, a family, or a business enterprise, find the security solution that works best for you.</p> 
           
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>

</section>{/* <!-- End Hero --> */}

  <main id="main">

   {/* <!-- NEW PRICING SECTION --> */} 

    <section className="section">
      <div className="container">

        <div className="row justify-content-center text-center">
          <div className="col-md-7 mb-5">
            <h2 className="section-heading">Choose Your Plan</h2>
    
          </div>
        </div>

        <div className="row align-items-stretch pricing-container">

          <div className="col-3 pricing-columns">
            <div className="pricing h-100 text-center">
            <span className="popularity smaller no-outline"></span>
              {/* <span>&nbsp;</span>
              <br /><br /><br /> */}
              <div className ="desktop-hidden">
                
              </div>

              <h3 className="price-title features">Features</h3>
 
              <ul className="list-unstyled">
                <li className="li-features">Customized Safety Zones</li>
                <li className="li-features">Set Emergency Contacts</li>
                <li className="li-features">Add Important Documents</li>
                <li className="li-features">SOS Panic Alert</li>
                <li className="li-features">24/7 WhatsApp Customer Support</li>
                <li className="li-features">Assigned Security Monitor</li>
                <li className="li-features">Add Up To 5 Family Members</li>
                <li ></li>
                <li ></li>
                {/* <li className="li-features">Emergency Notifications</li>
                <li className="li-features">Referral Discount</li>
                <li className="li-features">Travel Alerts</li>
                <li className="li-features">Corporate/ Government Plans</li>
                <li className="li-features">Flexible Billing</li> */}
              </ul>
              <div className="price-cta">
                
                <h3>Monthly Plans</h3>
                <br></br>
                <h3>Yearly Plans</h3>
                <br></br>
                <br></br>
                
                
                {/* <p><a href="#" className="btn btn-white">Choose Plan</a></p> */}
               
              </div>
            </div>
          </div>

          <div className="col-3 pricing-columns">
            <div className="pricing h-100 text-center popular silver faded">
            <span className="popularity smaller no-outline"></span>
              {/* <br /><br /> */}
              <div className ="desktop-hidden">
                
              </div>

              {/* <span>&nbsp;</span> */}
              <h3 className="price-title"><Shield className="material-icon sizing" /> Silver </h3>

              <ul className="list-unstyled">
                <li><i className='bx bx-check silver-check' ></i></li>
                <li><i className='bx bx-check silver-check'></i></li>
                <li><i className='bx bx-check silver-check'></i></li>
                <li><i className='bx bx-check silver-check'></i></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="price-cta price-white">
                {/* <p><a href="https://play.google.com/store/apps" className="btn btn-white">₦5,000/month</a></p>
                <p><a href="https://play.google.com/store/apps" className="btn btn-white">₦48,000/year</a></p> */}
                <h3 >₦5,000/month</h3>
                <br></br>
                <h3>₦48,000/year</h3>
                <p className="subtext">(Save 20%!)</p>
                <br></br>
                
              </div>
            </div>
          </div>
          <div className="col-3 pricing-columns">
            <div className="pricing h-100 text-center popular gold">
              <span className="popularity bold italics outlined">Most Popular</span>
              <h3 className="price-title"><PoliceShield className="material-icon sizing" /> Gold </h3>
             <ul className="list-unstyled">
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="price-cta price-white">
                
                {/* <p><a href="https://play.google.com/store/apps" className="btn btn-blue">₦10,000/month</a></p>
                <p><a href="https://play.google.com/store/apps" className="btn btn-blue">₦96,000/year</a></p> */}

                <h3 >₦10,000/month</h3>
                <br></br>
                <h3 >₦96,000/year</h3>
                <p className="subtext">(Save 20%!)</p>
                <br></br>
              </div>
            </div>
          </div>
          <div className="col-3 pricing-columns" >
            <div className="pricing h-100 text-center popular faded family">
              <span className="popularity smaller no-outline">Best Value</span>
              <h3 className="price-title"><FamilyGold className="material-icon sizing" /> Gold Family+ </h3>
              <ul className="list-unstyled">
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li><i className='bx bx-check gold-check'></i></li>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <div className="price-cta price-white">
                
                {/* <p><a href="https://play.google.com/store/apps" className="btn btn-white">₦25,000/month</a></p>
                <p><a href="https://play.google.com/store/apps" className="btn btn-white">Not Available</a></p> */}
                <h3 className="only-monthly">₦20,000/month</h3>
                <br className="desktop-hidden"></br>
                <br></br>
                <br></br>
                <br></br>
              </div>
            </div>
          </div>
        </div>
        <br></br>
      <p>* Payment will be charged to your Google Play or Apple ID at the confirmation of purchase. You may cancel at any time. Subscription automatically renews unless cancelled before the end of the current billing period.</p>
     
     <div className="feature-descriptions">
      <br></br><br></br>
      <h3> Feature Descriptions:</h3>
      <br></br>
      <p><strong>Customized Safety Zones:</strong> One of our core Tethered GPS features gives you the ability to create customized safety zones. These are the geographic areas where you spend the most time, like your home, your place of work, and friends or family you frequency visit.</p>
      <p><strong>Set Emergency Contacts:</strong> To further enhance your security, add emergency contacts directly to your account. This allows us to reach out to those most important to you in the event of a security situation.</p>
      <p><strong>Add Important Documents:</strong> Allows you to add important documents to your account. This ensures your most important documents are accessible to you during a security event and can help identify you if needed.</p>
      <p><strong>SOS Panic Alert:</strong> Allows you to instantly generate an emergency SOS to alert our agents of a security situation. Our agents will attempt to initiate contact to understand your situaion. </p>
      <p><strong>24/7 WhatsApp Customer Support:</strong> Get access to your own security monitor 24/7 through WhatsApp. Get questions answered, get assitance. Your agent is there to support you. </p>
      <p><strong>Assigned Security Monitor:</strong> Have a dedicated assigned security monitor who is there to assist and help you in case of a security event.</p>
      <p><strong>Add Up To 5 Family Members:</strong> The Tethered GPS Family Gold Membership allows you to add up to 5 family members on a single plan. Once enrolled, simply add their emails in your membership aread and they'll be able to setup their own Tethered GPS accounts.</p>

     </div>
     
      </div>
    </section>

  </main> {/*<!-- End #main --> */}

    </div>
  )
}
 