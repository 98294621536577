import React from 'react';

export const HamburgerNav = () => {
    React.useEffect(() => {
        window.addEventListener('click', hamburgerNavMenu);
        // cleanup this component
        return () => {
          window.removeEventListener('click', hamburgerNavMenu);
        };
      }, []);

  const hamburgerNavMenu = (event) => {

    /**
     * Easy selector helper function
     */

    const select = (el, all = false) => {
      el = el.trim()
      if (all) {
        return [...document.querySelectorAll(el)]
      } else {
        return document.querySelector(el)
      }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all)
      if (selectEl) {
        if (all) {
          selectEl.forEach(e => e.addEventListener(type, listener))
        } else {
          selectEl.addEventListener(type, listener)
        }
      }
    }

      /**
       * Mobile nav toggle
       */
    on('click', '.mobile-nav-toggle', function(e) {
      // console.log('click')
      select('#header').classList.toggle('navbar-mobile')
      select('#header').classList.toggle('navbar')
      this.classList.toggle('bi-list')
      this.classList.toggle('bi-x')
    })

    // TO CLOSE THE HAMBURGER NAV AFTER CLICKING LINK TO NEW PAGE

    on('click', '#close-nav', function(e) {
      // console.log('click-nav')
      select('#header').classList.remove('navbar-mobile')
      select('#header').classList.add('navbar')
      select('.mobile-nav-toggle').classList.remove('bi-x')
      select('.mobile-nav-toggle').classList.add('bi-list')
    })
  };
}