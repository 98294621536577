import TutorialNavbar from '../../components/TutorialNavbar.js'

// styles
import './Tutorials.css'

// images import 
import Image1 from '../../assets/img/tutorial-screenshots/respond-to-alert/step1-login-screen.png'
import Image2 from '../../assets/img/tutorial-screenshots/respond-to-alert/alert1.png'
import Image3 from '../../assets/img/tutorial-screenshots/respond-to-alert/alert2.png'
import Image4 from '../../assets/img/tutorial-screenshots/respond-to-alert/step1-login-screen.png'
import Image5 from '../../assets/img/tutorial-screenshots/respond-to-alert/warning1.png'
import Image6 from '../../assets/img/tutorial-screenshots/respond-to-alert/warning2.png'

export default function TutorialRespondToAlert() {

  return (
    <div>
      {/* <!-- ======= Hero Section ======= --> */} 
      <section className="hero-section" id="hero">
        <div className="wave">
          <svg width="100%" height="355px" viewBox="0 0 1920 300" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Apple-TV" transform="translate(0.000000, -402.000000)" fill="#FFFFFF">
                <path d="M0,439.134243 C175.04074,464.89273 327.944386,477.771974 458.710937,477.771974 C654.860765,477.771974 870.645295,442.632362 1205.9828,410.192501 C1429.54114,388.565926 1667.54687,411.092417 1920,477.771974 L1920,757 L1017.15166,757 L0,757 L0,439.134243 Z" id="Path"></path>
              </g>
            </g>
          </svg>
        </div>

        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 hero-text-image">
              <div className="row">
                <div className="col-lg-8 text-center text-lg-start">
                  <h1 data-aos="fade-right">TETHERED GPS Tutorials. <br /> </h1>
                  <p className="mb-5" data-aos="fade-right" data-aos-delay="100">Simple Step-By-Step Guides.</p>  
                </div>
              </div>
            </div>
          </div>
        </div>

      </section> {/* <!-- End Hero --> */} 

      {/* Tutorial Navbar Import */}
      <TutorialNavbar />

      <main id="main">

      {/* 
      
      <!-- ======= Tutorial Section ======= -->
    
      <!-- ======= Tutorial: How To Respond To An Alert ======= -->
      
      */} 

      <section className="section">
        <div className="container" id="respond-to-alert">
          <div className="row justify-content-center text-center mb-5" data-aos="fade">
            <div className="col-md-6 mb-5 tutorial-container">
              <h2 className="section-heading" >How To Respond To An Alert</h2>
              <div className="tutorial-content">
                <div className="step tutorial-text-box">
                  <h3>Purpose:</h3>
                  <p> When we have reason to be concerned for your safety, a Tethered security monitor will work to initiate contact with you through the app. You’ll receive notifications and alert notices within the app. Once received, you’ll need to enter your secret pin to confirm whether you’re safe. 
                    <br /><br />
                    We’ll cover two scenarios here: 
                    <br /><br />
                    Scenario A: Responding To An Alert
                    <br /><br />
                    Scenario B: Responding To A Warning
                  </p>
                  <br />
                  <h3>SCENARIO A: Responding To An Alert</h3>
                  <h3>Step 1:</h3>
                  <p>Login to Tethered App using your email and password.</p>
                </div>
              </div>
              <div className="tutorial-img-container">
                <img src={Image1} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="step tutorial-text-box">
                  <h3>Step 2: </h3>
                  <p>Click the big red ALERT notification.</p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Image2} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="step tutorial-text-box">
                  <h3>Step 3: </h3>
                  <p>Enter your private pin to confirm you’re safe. </p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Image3} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="step tutorial-text-box">
                  <h3> SCENARIO B: Responding To A Warning </h3>
                  <h3> Step 1: </h3>
                  <p>Login to Tethered App using your email and password.</p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Image4} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="step tutorial-text-box">
                  <h3>Step 2:</h3>
                  <p> Click the big yellow WARNING notification. </p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Image5} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="step tutorial-text-box">
                  <h3>Step 3:</h3>
                  <p>Enter your private pin to confirm you’re safe.  </p>
                </div>
              </div>

              <div className="tutorial-img-container">
                <img src={Image6} alt="tutorial" className="img-fluid tutorial-img" />
              </div>

              <div className="tutorial-content">
                <div className="step tutorial-text-box">
                  <h3>Finished!</h3>
                  <p> Any issues with setup? Contact us at  <a href="mailto:support@tetheredgps.com">support@tetheredgps.com</a> </p>
                </div>
              </div>

              <div className="tutorial-content">
    
                <div className="step tutorial-text-box">
                  <h3>Next Steps:</h3>
                  <p> Continue your app setup by completing the next tutorial to  <a href="/tutorials-how-to-hide-the-app">How To Hide The App</a> </p>
                </div>
              </div>
            </div>
          </div>
        </div>
    
      </section>

    </main> {/* <!-- End #main --> */} 

      {/* <!-- ======= JS Files Import ======= --> 
      <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a> */}

      {/* <!-- Vendor JS Files --> */} 
      <script src="../vendor/aos/aos.js"></script>
      <script src="../vendor/bootstrap/js/bootstrap.bundle.min.js"></script>
      <script src="../vendor/swiper/swiper-bundle.min.js"></script>
      <script src="../vendor/php-email-form/validate.js"></script>

      {/* <!-- Template Main JS File --> */} 
      <script src="../scripts/main.js"></script>
      <script src="../scripts/accordion.js"></script>

    </div>
  )
}
 