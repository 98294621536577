import {Link } from 'react-router-dom'
import './TutorialNavbar.css'

export default function TutorialNavbar() {
  
  return (
    <div className="tutorial-navbar" >
        <ul>
            <li><Link to="/tutorials-create-a-safety-zone">Create A Safety Zone</Link></li>
            <li><Link to="/tutorials-create-a-contact">Create A Contact</Link></li>
            <li><Link to="/tutorials-add-a-document">Add A Document</Link></li>
            <li><Link to="/tutorials-activate-panic-button">Activate Panic Button</Link></li>
            <li><Link to="/tutorials-respond-to-alert">Respond To An Alert</Link></li>
            <li><Link to="/tutorials-how-to-hide-the-app">How To Hide The App</Link></li>  
        </ul>
    </div>
  )
}